<template>
	<div class="home" ref="homme">
		<van-pull-refresh v-model="isLoading" @refresh="onRefresh" :loading-text="loadingText" :pulling-text="pullingText" :loosing-text="loosingText">
			<van-list v-model="loading" :finished="finished" :finished-text="bomTitle" @load="onLoad" :offset="20" class="main" loading-text="Loading...">
				<div class="newsList" v-for="(item, index) in list" :key="index">

					<div class="adsItem" v-if="item.type && item.type === -3">
						<ins class="adsbygoogle" style="display: block" :data-ad-client="shell.client" :data-ad-slot="shell.slot1" data-ad-format="auto" data-full-width-responsive="true"></ins>
					</div>

					<div class="newsList_center" v-if="!item.type" @click="goLanding(item.id)">
						<div class="word">
							<div class="title">{{ item.title }}</div>
							<div class="newsInfo">
								<div>
									{{ item.channel }}
								</div>
								<div class="round"></div>
								<div>
									{{
                    $global.formatDate(item.publishedTime, "yyyy-MM-dd hh:mm")
                  }}
								</div>
							</div>
						</div>
						<div class="picurl">
							<img v-lazy="item.headImg" alt="" />
						</div>
					</div>

					<div class="adsItem" v-if="item.type && item.type === -1">
						<ins class="adsbygoogle" style="display: block" :data-ad-client="shell.client" :data-ad-slot="shell.slot1" data-ad-format="auto" data-full-width-responsive="true"></ins>
					</div>
					<div class="adsItem" v-if="item.type && item.type === -2">
						<ins class="adsbygoogle" style="display: block" :data-ad-client="shell.client" :data-ad-slot="shell.slot2" data-ad-format="auto" data-full-width-responsive="true"></ins>
					</div>

					<!-- <section></section> -->
				</div>
			</van-list>
		</van-pull-refresh>
		<!-- <section class="no-data-row" v-if="!isData && requirDataAlready">
      <img src="@/assets/news/nodata.png" alt="" />
      <div class="no-data">
        Uh-oh. What you were looking for got away from you
      </div>
    </section> -->
		<div class="loading" v-if="!requirDataAlready">
			<img src="@/assets/news/loading.png" alt="" />
		</div>
	</div>
</template>
<script>
import "@/css/news/home.scss";
export default {
	name: "home",
	data() {
		return {
			list: [],
			hasNext: false,
			isLoading: false, // 控制下拉刷新的加载状态
			loading: false, // 控制上拉加载的加载状态
			finished: false, // 是否已加载完全部数据
			bomTitle: "-- END --", // 上拉加载完成时的文本
			loadingText: "Under loading...", // 加载过程提示文案
			pullingText: "Pull down to refresh...", //下拉过程提示文案
			loosingText: "Release to refresh...", // 释放过程提示文案
			page: 1,
			type: "",
			isinitialization: false,
			isData: false,
			requirDataAlready: true,
			shell: null,
		};
	},
	mounted() {
		let _this = this;
		this.type = this.$route.query.type;
		console.log(this.type, "this.type");

		console.log(window.__DOMAIN_CONFIG__);
		this.shell = window.__DOMAIN_CONFIG__;

		this.$eventrackFb("show_page_h5", "expose", {
			pve_cur: "/h5page/x/x/",
			website: this.website,
			type: this.type,
		});

		window.onload = () => {
			console.log("Window loaded");
		};

		setTimeout(() => {
			window.addAds();
			setTimeout(() => {
				const iframes = document.querySelectorAll("iframe");
				const tracker = new IframeTracker();
				iframes.forEach((iframe) => {
					tracker.addIframe(iframe, (iframeElement) => {
						console.log("Iframe 点击触发了!", iframeElement);
						const adContainer =
							iframeElement.closest("ins.adsbygoogle");
						if (adContainer) {
							tracker.trackEvent("ad_iframe_clicked", "click", {
								"data-ad-slot": adContainer.dataset.adSlot,
							});
						}
					});
				});
			}, 500); // 适当调整这里的延时以确保 iframe 已插入
		}, 1500);

		class IframeTracker {
			constructor(resolution = 200) {
				this.resolution = resolution;
				this.iframes = [];
				this.interval = null;
				this.activeIframe = null; // 存储最近被点击的iframe
				this.setupVisibilityTracking();
			}

			addIframe(element, callback) {
				const adContainer = element.closest("ins.adsbygoogle");
				const iframe = {
					element: element,
					callback: callback,
					hasTracked: false,
					adSlot: adContainer ? adContainer.dataset.adSlot : null,
				};
				this.iframes.push(iframe);

				if (!this.interval) {
					this.interval = setInterval(
						() => this.checkClick(),
						this.resolution
					);
				}
			}

			checkClick() {
				const activeElement = document.activeElement;
				if (activeElement) {
					this.iframes.forEach((iframe) => {
						if (
							activeElement === iframe.element &&
							!iframe.hasTracked
						) {
							iframe.callback(iframe.element);
							iframe.hasTracked = true;
							this.activeIframe = iframe; // 更新最近被点击的iframe
						}
					});
				}
			}

			setupVisibilityTracking() {
				const hiddenProperty =
					"hidden" in document
						? "hidden"
						: "webkitHidden" in document
						? "webkitHidden"
						: "mozHidden" in document
						? "mozHidden"
						: null;
				const visibilityChangeEvent = hiddenProperty.replace(
					/hidden/i,
					"visibilitychange"
				);

				const onVisibilityChange = () => {
					if (!document[hiddenProperty]) {
						console.log("页面激活状态,返回页面?");
						this.activeIframe = null; // 重置最近被点击的iframe记录
					} else {
						console.log("页面非激活状态");
						if (this.activeIframe) {
							// 上报逻辑
							this.trackEvent(
								"ad_iframe_leave_clicked",
								"click",
								{
									"data-ad-slot": this.activeIframe.adSlot,
								}
							);
						}
					}
				};

				document.addEventListener(
					visibilityChangeEvent,
					onVisibilityChange
				);
			}

			trackEvent(eventName, eventValue, dataAttributes) {
				// 事件追踪逻辑
				console.log(
					`${eventName} with value ${eventValue} on`,
					dataAttributes
				);

				_this.$eventrackFb(eventName, eventValue, {
					...dataAttributes,
				});
			}
			// REF: https://www.cnblogs.com/lisao-Ak47/p/10764391.html
		}

		this.setupAdObserver();
	},
	methods: {
		setupAdObserver() {
			let _this = this;
			//设置 MutationObserver 监听特定元素
			const observer = new MutationObserver((mutations) => {
				mutations.forEach((mutation) => {
					if (
						mutation.type === "childList" &&
						mutation.addedNodes.length > 0
					) {
						// 检查是否是广告相关的 DOM 变更
						if (mutation.target.matches("ins.adsbygoogle")) {
							console.log("AdSense ad loaded");
							console.log(mutation.target);
							console.log(mutation.target.dataset.adSlot);
							_this.$eventrackFb("ad_loaded", "load", {
								"data-ad-slot": mutation.target.dataset.adSlot,
							});

							_this.setupIntersectObserve();
						}
					}
				});
			});

			// 观察器的配置（观察子节点变动）
			const config = { childList: true, subtree: true };

			// 开始观察当前组件根元素下的所有变化
			this.$refs.homme && observer.observe(this.$refs.homme, config);
		},
		setupIntersectObserve() {
			let _this = this;
			//配置一个回调，当目标元素进入或退出另一个元素（通常是视口）的给定集合时触发
			const observer = new IntersectionObserver(
				(entries) => {
					entries.forEach((entry) => {
						// 检查是否已经处理过该广告
						if (
							entry.isIntersecting &&
							!entry.target.dataset.viewed
						) {
							console.log("Ad viewed", entry.target);
							// 标记广告为已处理
							entry.target.dataset.viewed = "true";
							console.log(entry.target.dataset.adSlot);
							_this.$eventrackFb("ad_viewed", "expose", {
								"data-ad-slot": entry.target.dataset.adSlot,
							});

							// 取消观察当前元素
							observer.unobserve(entry.target);
						}
					});
				},
				{ threshold: 0.5 } // 至少50%的广告内容在视口中时触发
			);

			const adElements = this.$el.querySelectorAll("ins.adsbygoogle");
			adElements.forEach((el) => observer.observe(el));
		},

		getList() {
			return this.$global.server
				.getList({
					page: this.page,
					cid: this.shell.cid,
					sdu: 2,
					size: 30,
				})
				.then((res) => {
					this.page++;
					this.loading = false;
					this.isLoading = false;
					this.finished = !res.data.hasNext;

					if (res.result_code === 200) {
						let data = res.data.source;
						this.list.push(...data);

						// Insert elements conditionally using push and unshift
						if (
							!this.list[3]?.type &&
							[
								"apicostco.sbs",
								"apishopee.sbs",
								"apilazada.sbs",
							].includes(this.$globalState.host)
						) {
							this.list.unshift({ type: -3 });
						} else if (
							!this.list[3]?.type &&
							this.$globalState.host !== "apicostco.sbs" &&
							this.$globalState.host !== "apishopee.sbs" &&
							this.$globalState.host !== "apilazada.sbs"
						) {
							this.list.splice(3, 0, { type: -1 });
						}
						if (!this.list[7]?.type) {
							this.list.splice(7, 0, { type: -2 });
						}

						this.isData = this.list.length > 0;
						this.hasNext = res.data.hasNext;
					} else {
						this.requirDataAlready = true;
					}

					if (!this.isinitialization) {
						this.isinitialization = true;
						this.$eventrackFb("show_pageNews_h5", "expose", {
							website: this.website,
							pve_cur: "/h5page/news/x/",
							type: this.type,
							state:
								res.result_code === 200 &&
								res.data.source.length > 1
									? 1
									: 0,
						});
					}
				})
				.catch((err) => {
					this.requirDataAlready = true;
					this.isinitialization = true;
					this.$eventrackFb("show_pageNews_h5", "expose", {
						website: this.website,
						pve_cur: "/h5page/news/x/",
						type: this.type,
						state: -1,
					});
				});
		},
		goLanding(id) {
			// this.$eventrack("clicknews_h5", 'click', {
			//   extensions: JSON.stringify({
			//     id: id,
			//   })
			// });
			this.$eventrackFb("clicknews_h5", "click", {
				website: this.website,
				id: id,
			});
			this.$router.push({ name: "landing", query: { id: id } });
		},
		onRefresh() {
			this.finished = false;
			this.loading = true;
			this.page = 1;
			this.onLoad();
		},
		onLoad() {
			this.getList();
			console.log("onLoad");
		},
	},
};
</script>